<template>
  <div>
    <div class="header">
      <div>
        <el-page-header @back="goBack" :content="recruitTitle + '报名名单'">
        </el-page-header>
      </div>
      <el-button
        size="small"
        type="primary"
        icon="el-icon-download"
        @click="download()"
        >导出名单</el-button
      >
    </div>
    <ListFilter :total="total" @close="putAway">
      关键字：
      <el-input
        placeholder="请输入关键字"
        v-model="formInline.keywords"
        @input="loadData()"
        size="mini"
        style="margin-right: 10px; width: 300px"
        prefix-icon="el-icon-search"
        clearable
      >
      </el-input>
      状态：
      <el-select
        placeholder="确认结果"
        size="mini"
        v-model="formInline.state"
        @change="loadData()"
        clearable=""
      >
        <el-option
          v-for="item in cofirmOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </ListFilter>
    <el-table
      :data="list"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column type="index" label="序号" width="50"></el-table-column>
      <el-table-column label="报名人" width="120">
        <template slot-scope="scope">
          <span class="clickColor">{{
            scope.row.signupUser ? scope.row.signupUser.name : ""
          }}</span>
        </template>
      </el-table-column>

      <el-table-column label="报名人电话" width="150">
        <template slot-scope="scope">
          <span class="clickColor">{{
            scope.row.signupUser ? scope.row.signupUser.phone : ""
          }}</span>
        </template>
      </el-table-column>

      <el-table-column label="报名提成/元" width="120">
        <template slot-scope="scope">
          <span class="clickColor">{{ scope.row.signupUserMoney }}</span>
        </template>
      </el-table-column>

      <el-table-column label="推荐人">
        <template slot-scope="scope">
          <span class="clickColor">{{
            scope.row.recommendUser ? scope.row.recommendUser.name : ""
          }}</span>
        </template>
      </el-table-column>

      <el-table-column label="推荐提成/元">
        <template slot-scope="scope">
          <span class="clickColor">{{ scope.row.recommendUserMoney }}</span>
        </template>
      </el-table-column>

      <el-table-column label="招聘企业">
        <template slot-scope="scope">
          {{ scope.row.job.enterpriseName }}
        </template>
      </el-table-column>

      <el-table-column label="职位名称">
        <template slot-scope="scope">
          <span class="clickColor">{{ scope.row.job.name }}</span>
        </template>
      </el-table-column>

      <!-- <el-table-column label="工作地点" show-overflow-tooltip="" width="300">
        <template slot-scope="scope">
          {{ scope.row.job.location }},{{ scope.row.job.locationDetail }}
        </template>
      </el-table-column>

      <el-table-column label="薪资范围" width="180">
        <template slot-scope="scope">
          {{ scope.row.job.salaryMinAmount }} ~
          {{ scope.row.job.salaryMaxAmount }}/人·{{ scope.row.job.salaryUnit }}
        </template>
      </el-table-column>

      <el-table-column label="创建时间" width="150">
        <template slot-scope="scope">
          {{ scope.row.job.createTime }}
        </template>
      </el-table-column> -->

      <el-table-column label="职位有效期">
        <template slot-scope="scope">
          {{ scope.row.job.validityTime }}
        </template>
      </el-table-column>

      <el-table-column label="状态">
        <template slot-scope="scope">
          {{ scope.row.state }}
        </template>
      </el-table-column>

      <el-table-column label="操作" fixed="right" width="180">
        <template slot-scope="scope">
          <el-button
            slot="reference"
            @click="addViewResult(scope.row)"
            type="text"
            >人员确认</el-button
          >
          <el-button @click="getHitory(scope.row)" type="text"
            >查看操作历史</el-button
          >
          <!-- <el-divider direction="vertical"></el-divider>
          <el-dropdown>
            <el-button type="text"
              >更多<i class="el-icon-arrow-down el-icon--right"></i
            ></el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click="publish">发布</el-dropdown-item>
              <el-dropdown-item
                v-prev
                :path="'/system/recruit/recruitEnlist'"
                :query="{ code: scope.row.content }"
                >报名人员</el-dropdown-item
              >
              <el-dropdown-item
                v-prev
                :path="'/system/content/editContent'"
                :query="{ code: scope.row.content }"
                >编辑宣传页</el-dropdown-item
              >
              <el-dropdown-item
                v-prev
                :path="'/system/content/Detail'"
                :query="{ code: scope.row.content }"
                >查看宣传页</el-dropdown-item
              >
              <el-dropdown-item>终止</el-dropdown-item>
              <el-dropdown-item style="color: #f56c6c">删除</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown> -->
        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top: 10px">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNumber"
        :page-size="pageSize"
        layout="prev,pager, next"
        :total="total"
      >
      </el-pagination>
    </div>

    <el-dialog
      width="600px"
      title="人员确认"
      :visible.sync="viewResultFormVisible"
    >
      <el-form
        label-position="top"
        :model="viewResultForm"
        class="ruleForm"
        :rules="viewResultFormRules"
        ref="ruleForm"
      >
        <el-form-item label="确认结果" prop="state">
          <el-select
            v-model="viewResultForm.state"
            placeholder="确认结果"
            style="width: 100%"
          >
            <el-option
              v-for="item in cofirmOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注" prop="stateRemark">
          <el-input
            type="textarea"
            placeholder="请输入确认结果备注"
            v-model="viewResultForm.stateRemark"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="viewResultFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <el-drawer
      title="查看操作历史"
      :visible.sync="drawer"
      direction="rtl"
      size="500px"
    >
      <div style="padding-left: 20px">
        <el-timeline>
          <el-timeline-item
            v-for="(history, index) in historyList"
            :key="index"
            :timestamp="history.time"
          >
            <div>
              <h4>{{ history.state }}</h4>
            </div>
            <div>{{ history.stateRemark || "--" }}</div>
          </el-timeline-item>
        </el-timeline>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { api } from '/src/api/base';
export const recruitDescribe = api('/recruit')('recruit.describe.json');
export const recruitEnlistList = api('/recruitEnlist')(
  'recruitEnlist.list.json'
);
export const recruitEnlistEdit = api('/recruitEnlist')(
  'recruitEnlist.edit.json'
);
import { parseJsonToTable } from '/src/lib/dev';
export default {
  components: {
    ListFilter: () => import('/src/components/filter')
  },
  data() {
    return {
      viewResultFormVisible: false,
      code: '',
      pageSize: 10,
      pageNumber: 1,
      total: 0,
      formInline: {
        keywords: '',
        state: ''
      },
      list: [],
      viewResultForm: {
        state: '',
        stateRemark: ''
      },
      viewResultFormRules: {
        state: [{ required: true, message: '请选择状态', trigger: 'change' }],
        stateRemark: [
          { required: true, message: '请输入备注', trigger: 'blur' }
        ]
      },
      cofirmOptions: [
        {
          label: '待确认',
          value: '待确认'
        },
        {
          label: '通过',
          value: '通过'
        },
        {
          label: '未通过',
          value: '未通过'
        },
        {
          label: '未入职',
          value: '未入职'
        },
        {
          label: '已入职',
          value: '已入职'
        },
        {
          label: '已离职',
          value: '已离职'
        }
      ],
      selectCode: '',
      drawer: false,
      historyList: [],
      selectData: [],
      recruitTitle: ''
    };
  },
  created() {
    this.code = this.$route.query.code;
    this.loadData();
    this.getRecruit();
  },
  methods: {
    async getRecruit() {
      const res = await recruitDescribe({ code: this.code });
      this.recruitTitle = res.name;
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.loadData();
    },
    handleCurrentChange(val) {
      this.pageNumber = val;
      this.loadData();
    },
    loadData() {
      const params = {
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
        ...this.formInline,
        jobCode: this.code
      };
      recruitEnlistList(params).then(res => {
        this.list = res.list;
        this.total = res.total;
      });
    },
    async getHitory(item) {
      this.drawer = true;
      this.historyList = JSON.parse(item.stateHistory);
      console.log(item);
    },
    putAway() {
      this.pageNumber = 1;
      this.formInline.keywords = '';
      this.loadData();
    },
    submitForm(form) {
      this.$refs[form].validate(async valid => {
        if (valid) {
          await recruitEnlistEdit({
            code: this.selectCode,
            ...this.viewResultForm
          }).then(() => {
            this.$refs[form].resetFields();
            this.viewResultFormVisible = false;
            this.loadData();
          });
        }
      });
    },
    addViewResult(item) {
      this.viewResultFormVisible = true;
      this.selectCode = item.code;
    },
    goBack() {
      window.history.go(-1);
    },
    handleSelectionChange(val) {
      this.selectData = val;
    },
    download() {
      if (this.selectData.length === 0) {
        return this.$message.error('请先选择数据！');
      }
      const _list = this.selectData.map(it => {
        return {
          ...it
        };
      });
      parseJsonToTable(
        '返佣名单',
        {
          用户编号: 'code',
          姓名: 'recommendUserMoney'
          // 手机号: "phone"
          // 身份证号: "idCardNumber",
          // 银行: "bankname",
          // 银行卡号: "bankcode",
          // 认证情况: "isAuthenticated"
        },
        _list,
        true
      );
    }
  }
};
</script>

<style></style>
